<div class="uj-flex uj-flex-col">
  <div class="uj-flex uj-flex-col sm:uj-flex-row uj-gap-c uj-relative">
    <div class="uj-flex sm:uj-grid sm:uj-grid-cols-7 uj-grow">
      <div
        [class.!uj-col-span-full]="isNewEntry()"
        [style.padding-left]="(isSmDown$ | async) ? '11px' : '0'"
        class="uj-col-span-1 sm:uj-col-span-4 uj-flex uj-flex-col uj-grow">
        <ui-library-form-field
          fullWidth
          [feedback]="phoneGroup().controls.phone.errors ? 'invalid' : 'none'"
          [label]="
            isFirstEntry() && !(isSmDown$ | async)
              ? ('telephone' | i18next | firstUppercase)
              : ''
          "
          [labelFor]="'addNumber' + componentId">
          <input
            ui-library-text-input
            [id]="'addNumber' + componentId"
            [positionInGroup]="
              (isSmDown$ | async) || isNewEntry() ? 'default' : 'start'
            "
            [placeholder]="'addNumber' | i18next"
            [formControl]="phoneGroup().controls.phone"
            (change)="onPhoneChange($event)"
            [feedback]="phoneGroup().controls.phone.errors ? 'invalid' : 'none'"
            type="text" />
        </ui-library-form-field>
      </div>
      <ui-library-form-field
        class="uj-flex uj-flex-col sm:uj-col-span-3"
        *ngIf="!isNewEntry()"
        [label]="
          isFirstEntry() && !(isSmDown$ | async)
            ? ('typ' | i18next | firstUppercase)
            : ''
        ">
        @if (!(isSmDown$ | async)) {
          <ng-template [ngTemplateOutlet]="phoneTypeInput"></ng-template>
        } @else {
          <ng-template [ngTemplateOutlet]="contextButton"></ng-template>
        }
      </ui-library-form-field>
    </div>
    <ng-container *ngIf="!isNewEntry()">
      @if (!(isSmDown$ | async)) {
        <div class="uj-flex sm:uj-col-end-7 sm:uj-items-end">
          <a
            ui-library-button-outline
            target="_blank"
            [href]="'tel:' + phoneGroup().controls.phone.value"
            class="uj-pointer-events-auto"
            iconOnly="phone"
            color="dark">
          </a>
        </div>
        <div class="uj-flex sm:uj-col-end-8 sm:uj-items-end">
          <ng-template [ngTemplateOutlet]="contextButton"></ng-template>
        </div>
      } @else {
        <div class="uj-pl-[11px]">
          <ng-template [ngTemplateOutlet]="phoneTypeInput"></ng-template>
        </div>
      }
    </ng-container>
    <div
      *ngIf="isSmDown$ | async"
      class="uj-absolute uj-w-[3px] uj-bg-violet-290/54 uj-rounded uj-h-full"></div>
  </div>

  <div
    *ngIf="
      showInputError && phoneGroup().controls.phone.errors?.message.key as error
    "
    class="uj-flex uj-gap-b uj-items-center uj-text-ui-negative">
    <unijob-icon
      icon="alert_triangle"
      size="12"></unijob-icon>
    <div class="text-s">
      {{ error | i18next | firstUppercase }}
    </div>
  </div>
</div>

<ng-template #phoneTypeInput>
  <ui-library-simple-select
    [formControl]="phoneGroup().controls.phoneType"
    [placeholderText]="'chooseCategory' | i18next"
    [options]="phoneTypeOptions"
    [positionInGroup]="(isSmDown$ | async) ? 'default' : 'end-seamless'"
    size="M"
    design="outline"
    backgroundColor="white"></ui-library-simple-select>
</ng-template>

<ng-template #contextButton>
  <div class="uj-flex sm:uj-col-end-8 sm:uj-items-end">
    <button
      ui-library-button-outline
      [positionInGroup]="(isSmDown$ | async) ? 'end-seamless' : 'default'"
      type="button"
      color="dark"
      iconOnly="menu_dots_vertical"
      [contextMenu]="moreActionsContextMenu"
      (mousedown)="$event.stopPropagation()"
      (touchstart)="$event.stopPropagation()"></button>
  </div>
</ng-template>

<ng-template #moreActionsContextMenu>
  <div class="uj-flex uj-flex-col">
    <a
      *ngIf="isSmDown$ | async"
      ui-library-button-text
      type="button"
      color="dark"
      target="_blank"
      [href]="'tel:' + phoneGroup().controls.phone.value"
      class="uj-pointer-events-auto"
      iconLeft="phone"
      color="dark">
      {{ "call" | i18next }}
    </a>
    <button
      (click)="setPrimaryEmail()"
      ui-library-button-text
      [disabled]="!!phoneGroup().controls.isPrimary.value"
      type="button"
      color="dark"
      [iconLeft]="phoneGroup().controls.isPrimary.value ? 'pin_pinned' : 'pin'">
      {{ "primary" | i18next | firstUppercase }}
    </button>
    <button
      (click)="deletePhone()"
      ui-library-button-text
      type="button"
      color="dark"
      iconLeft="trash"
      class="hover:uj-text-ui-negative">
      {{ "delete" | i18next }}
    </button>
  </div>
</ng-template>

<app-confirmation-modal
  [modalId]="deleteConfirmationModalId"
  [title]="'deleteEntry' | i18next | firstUppercase"
  [cancelText]="'cancel' | i18next | firstUppercase"
  [confirmText]="'delete' | i18next | firstUppercase"
  (confirmed)="deletePhone()"
  [size]="'S'"
  [hasCustomContent]="true">
  <ng-container content>
    <div class="uj-flex uj-flex-col uj-gap-d">
      <span>{{
        "areYouSureYouWantToDeleteThisEntry" | i18next | firstUppercase
      }}</span>
      @if (
        this.phoneGroup().value.isPrimary && this.phonesFormArray.length > 1
      ) {
        <unijob-info-box
          class="uj-relative"
          [message]="
            formService.phonesFormArray.controls.at(1)?.value?.phone ?? ''
          "></unijob-info-box>

        <span>{{ "willBeYourPrimaryPhone" | i18next }}</span>
      }
    </div>
  </ng-container>
</app-confirmation-modal>
